import axios from "axios";

const BASE_URL = "https://bookmark.com.bd/api";
const userInfo = JSON.parse(localStorage.getItem("persist:root"))?.userInfo;
const currentUser = userInfo && JSON.parse(userInfo).currentUser;
const TOKEN = currentUser?.accessToken

export const publicRequest = axios.create({
  baseURL: BASE_URL,
});

export const userRequest = axios.create({
  baseURL: BASE_URL,
  headers: { token: `Bearer ${TOKEN}` },
});