import { createSlice } from "@reduxjs/toolkit";

export const adminSlice = createSlice({
  name: "admin",
  initialState: {
    Admins: [],
    isFetching: false,
    error: false,
  },
  reducers: {
    //get all
    getAdminStart: (state) => {
      state.isFetching = true;
      state.error = false;
    },
    getAdminSuccess: (state, action) => {
      state.isFetching = false;
      state.Admins = action.payload;
    },
    getAdminFailur: (state) => {
      state.isFetching = false;
      state.error = true;
    },

    //add
    addAdminStart: (state) => {
      state.isFetching = true;
      state.error = false;
    },
    addAdminSuccess: (state, action) => {
      state.isFetching = false;
      state.Admins.push(action.payload);
    },
    addAdminFailur: (state) => {
      state.isFetching = false;
      state.error = true;
    },
  },

  //UPDATE
  updateAdminStart: (state) => {
    state.isFetching = true;
    state.error = false;
  },
  updateAdminSuccess: (state, action) => {
    state.isFetching = false;
    state.Admins[
      state.Admins.findIndex((item) => item._id === action.payload.id)
    ] = action.payload.updateAdmin;
  },
  updateAdminFailure: (state) => {
    state.isFetching = false;
    state.error = true;
  },

  //DELETE
  deleteAdminStart: (state) => {
    state.isFetching = true;
    state.error = false;
  },
  deleteAdminSuccess: (state, action) => {
    state.isFetching = false;
    state.Admins.splice(
      state.Admins.findIndex((item) => item._id === action.payload),
      1
    );
  },
  deleteAdminFailure: (state) => {
    state.isFetching = false;
    state.error = true;
  },
});

export const { getAdminStart, getAdminSuccess, getAdminFailur, addAdminStart, addAdminSuccess, addAdminFailur, updateAdminStart, updateAdminSuccess, updateAdminFailure, deleteAdminStart, deleteAdminSuccess, deleteAdminFailure } =
  adminSlice.actions;

export default adminSlice.reducer;
