import './App.css';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Navbar from './component/Navbar';
import Sidebar from './component/Sidebar'
import Login from './component/Login';
import { useDispatch, useSelector } from 'react-redux';
import { isAuthenticated } from './util/auth';
import { logout } from './redux/authRedux';
import Dashboard from './component/Dashboard';
import AddBook from './component/AddBook';
import BookList from './component/BookList';
import { useEffect } from 'react';
import {loadMedia, getTags } from './util/api';
import ChangePassword from './component/ChangePassword';

function App() {
  const dispatch = useDispatch()
  const publisher = useSelector((state) => state.userInfo.currentUser)


  // window.onbeforeunload = () => {
  //   signout()
  // }

  var delay = 60000;
  setTimeout(function () {
    isAuthenticated()? console.log("Connected") : dispatch(logout());
  }, delay);


  const sessionexpire = () =>{
    isAuthenticated()? console.log("Connected") : dispatch(logout());
  }

  useEffect(() => {
    sessionexpire()
    loadMedia(dispatch)
    getTags(dispatch)
  }, [])

  return (
    <BrowserRouter>
      <div className="App hero-area">
        {publisher? 
        <>
          <Sidebar/>
          <div className="content-area">
            <Navbar/>
            <Routes>
              <Route exact path="/dashboard" element={<Dashboard/>}/>
              <Route path="/addbook" element={<AddBook/>}/>
              <Route exact path="/booklist" element={<BookList/>}/>
              <Route exact path="/changepassowrd" element={<ChangePassword/>}/>
            </Routes>
          </div>
        </> : <Login/>}
      </div>
    </BrowserRouter>
  );
}

export default App;
