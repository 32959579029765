import React from "react";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { createAlbum, getPublishers, loadMedia, updatePublisher } from "../util/api";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTimes,
  faFilePdf,
  faFolder,
} from "@fortawesome/free-solid-svg-icons";
import axios from "axios";
import BeatLoader from "react-spinners/BeatLoader";
import ModalImage from "react-modal-image";
// import Album from "./Album";
// import PDFViewer from 'pdf-viewer-reactjs'
// import { LazyLoadImage } from 'react-lazy-load-image-component';

export default function MediaLibrary({ uploadImg, publisher }) {
  const [loader, setLoader] = useState(false);
  let files = [];
  const dispatch = useDispatch();
  let images = [];
  let library = {};
  const [urls, setUrls] = useState([]);
  const [progress, setProgress] = useState(0);
  const [upImg, setupImg] = useState(publisher === null? true : false);
  const [mediaLibrary, setmediaLibrary] = useState(publisher !== null? false : true);
  const [albums, setalbums] = useState(publisher === null? false : true);
  const [sortedMedia, setSorting] = useState([]);
  const [open, setOpen] = useState(false);
  const [isAlbumOpen, setisAlbumOpen] = useState(false)
  const [isPublisherFound, setisPublisherFound] = useState(false)

  const media = useSelector((state) => state.media.allmedia);
  const user = useSelector((state) => state.userInfo.currentUser);
  const publishersAlbum = useSelector((state) => state.publisher.publishers);
  

  const handleUpimg = () => {
    setupImg(true);
    setmediaLibrary(false);
    setalbums(false);
  };
  const handleMediaLibrary = () => {
    setupImg(false);
    setmediaLibrary(true);
    setalbums(false);
  };
  const handleAlbum = () => {
    setupImg(false);
    setmediaLibrary(false);
    setalbums(true);
  };

  const handleChange = (e) => {
    files = e.target.files;
    const data = new FormData();
    for (let i = 0; i < files.length; i++) {
      library = { fileName: files[i].name, fileType: files[i].type, entityId:user._id };
      axios
        .post("https://bookmark.com.bd/api/media/add", library)
        .then(() => {
          console.log("success");
        })
        .catch((e) => {
          console.log("error", e);
        });
      setUrls((prevState) => [...prevState, library.fileName]);
      data.append("file", files[i]);
    }

    axios
      .post("https://bookmark.com.bd/api/uploads", data)
      .then(() => {
        console.log("success");
      })
      .catch((e) => {
        console.log("error", e);
      })
      .then(() => loadMedia(dispatch));
  };

  const handleUpload = (fileName) => {
    const imgUrl = `https://bookmark.com.bd/api/alluploads/${fileName}`;
    uploadImg(imgUrl);
  };

  const handleDelete = (imagename) => {
    setLoader(true);
    setTimeout(() => {
      setLoader(false);
    }, 3000);
    axios
      .delete(`https://bookmark.com.bd/api/media/${imagename._id}`)
      .then(() => {
        console.log("success");
      })
      .catch((e) => {
        console.log("error", e);
      });

    axios
      .delete(`https://bookmark.com.bd/api/deletemedia/${imagename.fileName}`)
      .then(() => {
        console.log("success");
      })
      .catch((e) => {
        console.log("error", e);
      })
      .then(() => loadMedia(dispatch));
  };

  const handleClose = () => {
    setOpen(true);
  };

  return (
    <div className="media-page">
      <div className="row">
        <div className="col-lg-2 col-md-3">
          <div className="media-left">
            <h1>Insert Media</h1>
            <ul>
              <li>Create gallery</li>
              <li>Set featured Image</li>
              <li>Insert from url</li>
            </ul>
          </div>
        </div>
        <div className="col-lg-10 col-md-9">
          <div className="media-right">
            <ul className="upload-media-btns">
              <li
                className={upImg ? "active-upload-btn" : ""}
                onClick={handleUpimg}
              >
                Upload Files
              </li>
              <li
                className={mediaLibrary ? "active-upload-btn" : ""}
                onClick={handleMediaLibrary}
              >
                Media Library
              </li>
              <li
                className={albums ? "active-upload-btn" : ""}
                onClick={handleAlbum}
              >
                Albums
              </li>
            </ul>
            <div
              className="upload-media-body"
              style={upImg ? { display: "block" } : { display: "none" }}
            >
              {/* {urls.length !== 0 ? (
                <div className="image-gallery gallery-2">
                  {urls.map((url) => {
                    return (
                        <div className="single-upload-img">
                            <img src={`https://bookmark.com.bd/api/alluploads/${url}`} key={Math.random} alt={url} />
                        </div>
                    )
                  })}
                </div>
              ) : (
                ""
              )} */}
              <form>
                <h1>Drop files anywhere to upload</h1>
                <p>Or</p>
                <input type="file" multiple onChange={handleChange} />
                <a>Select Files</a>
              </form>
            </div>
            <div
              className="upload-media-body"
              style={mediaLibrary ? { display: "block" } : { display: "none" }}
            >
              <div className="image-gallery">
                {media.map((data) => (
                  <div className="single-upload-img" key={data._id}>
                    {/* <img src={`https://bookmark.com.bd/api/alluploads/${data.fileName}`} key={Math.random} /> */}
                    {/* <LazyLoadImage
                            src={`https://bookmark.com.bd/api/alluploads/${data.fileName}`}
                            effect="blur"
                          /> */}
                    {data.fileType === "application/pdf" ? (
                      <a
                        href={`https://bookmark.com.bd/api/alluploads/${data.fileName}`}
                        target="_blank"
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                          justifyContent: "center",
                          height: "100%",
                          fontSize: "12px",
                          textAlign: "center",
                          color: "#000000",
                        }}
                      >
                        <FontAwesomeIcon
                          fontSize="60"
                          style={{ marginBottom: "10px" }}
                          color="#0D99FF"
                          icon={faFilePdf}
                        />
                        {data.fileName}
                      </a>
                    ) : (
                      <ModalImage
                        small={`https://bookmark.com.bd/api/alluploads/${data.fileName}`}
                        large={`https://bookmark.com.bd/api/alluploads/${data.fileName}`}
                        alt="Library Gallery"
                      />
                    )}
                    <div className="upload-btn-group">
                      <button
                        className="up-btn"
                        onClick={() => handleUpload(data.fileName)}
                      >
                        Insert Image
                      </button>
                    </div>
                    <button
                      className="delete-btn"
                      onClick={() => handleDelete(data)}
                    >
                      <FontAwesomeIcon icon={faTimes} />
                    </button>
                  </div>
                ))}
                <BeatLoader color={"#f04a3f"} loading={loader} size={15} />
              </div>
            </div>
            <div
              className="upload-media-body"
              style={albums ? { display: "block" } : { display: "none" }}
            >
              {/* <div className="image-gallery">
                {publishersAlbum.map((folder) => {
                  return <Album key={folder._id} folder={folder} uploadImg={uploadImg} publisherId={publisher}/>
                })}
              </div> */}
            </div>
            <div className="upload-media-footer">
              <form>
                <div className="upload-btn-group">
                  <button className="up-btn" onClick={() => handleUpload()}>
                    Insert Image
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
