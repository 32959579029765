import { createSlice } from "@reduxjs/toolkit";


const passwordSlice = createSlice({
  name: "changepassword",
  initialState: {
    currentUser: null,
    isFetching: false,
    error: false,
  },
  reducers: {
    changeStart: (state) => {
      state.isFetching = true;
    },
    changeSuccess: (state, action) => {
      state.isFetching = false;
      state.currentUser = action.payload;
    },
    changeFailur: (state) => {
      state.isFetching = false;
      state.error = true;
    }
  },
});

export const { changeStart, changeSuccess, changeFailur } = passwordSlice.actions;
export default passwordSlice.reducer;