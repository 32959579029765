import { createSlice } from "@reduxjs/toolkit";

export const sliderSlice = createSlice({
  name: "slider",
  initialState: {
    sliders: [],
    isFetching: false,
    error: false,
  },
  reducers: {
    //get all
    getSliderStart: (state) => {
      state.isFetching = true;
      state.error = false;
    },
    getSliderSuccess: (state, action) => {
      state.isFetching = false;
      state.sliders = action.payload;
    },
    getSliderFailur: (state) => {
      state.isFetching = false;
      state.error = true;
    },

    //add
    addSliderStart: (state) => {
      state.isFetching = true;
      state.error = false;
    },
    addSliderSuccess: (state, action) => {
      state.isFetching = false;
      state.sliders.push(action.payload);
    },
    addSliderFailur: (state) => {
      state.isFetching = false;
      state.error = true;
    },

    //UPDATE
    updateSliderStart: (state) => {
      state.isFetching = true;
      state.error = false;
    },
    updateSliderSuccess: (state, action) => {
      state.isFetching = false;
      state.sliders[
        state.sliders.findIndex((item) => item._id === action.payload.id)
      ] = action.payload.updateSlider;
    },
    updateSliderFailure: (state) => {
      state.isFetching = false;
      state.error = true;
    },

    //DELETE
    deleteSliderStart: (state) => {
      state.isFetching = true;
      state.error = false;
    },
    deleteSliderSuccess: (state, action) => {
      state.isFetching = false;
      state.sliders.splice(
        state.sliders.findIndex((item) => item._id === action.payload),
        1
      );
    },
    deleteSliderFailure: (state) => {
      state.isFetching = false;
      state.error = true;
    },
  },
});

export const { getSliderStart, getSliderSuccess, getSliderFailur, addSliderStart, addSliderSuccess, addSliderFailur, updateSliderStart, updateSliderSuccess, updateSliderFailure,deleteSliderStart, deleteSliderSuccess, deleteSliderFailure } =
  sliderSlice.actions;

export default sliderSlice.reducer;
