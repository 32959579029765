import {React} from 'react'
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Link from 'react-router-dom'
import { changePassword } from '../util/api';
import { logout } from '../redux/authRedux';

export default function ChangePassword() {
    const [password, setoldpassword] = useState("");
    const [newpassword, setnewpassword] = useState("");
    const [confirmpassword, setconfirmPassword] = useState("");
    const dispatch = useDispatch();

    const email = useSelector((state) => state.userInfo.currentUser.email);

    const [failurMessage, setfailurMessage] = useState("");

    const handleClick = (e) =>{
        e.preventDefault()
        if (newpassword === confirmpassword) {
            console.log("password matched");
            try {
              const passwordDetails = {email, newpassword, password}
              changePassword(dispatch,passwordDetails).then(()=>dispatch(logout()));
              setfailurMessage("");
            } catch (error) {
              setfailurMessage("Something went wrong")
            }
        }else{
            setfailurMessage("Password didn't match");
        }
    };

  return (
    <div className="login">
      <p>Change Password</p>
      <form className="login-form">
        <div className="form-group">
          <label htmlFor="oldpass">Old Password</label>
          <input type="password" className="form-control" id="oldpass" onChange={e=>setoldpassword(e.target.value)} />
        </div>
        <div className="form-group">
          <label htmlFor="newpass">New Password</label>
          <input type="password" className="form-control" id="newpass" onChange={e=>setnewpassword(e.target.value)} />
        </div>
        <div className="form-group">
          <label htmlFor="confpass">Confirm Password</label>
          <input type="password" className="form-control" id="confpass" onChange={e=>setconfirmPassword(e.target.value)} />
          <p>{failurMessage}</p>
        </div>
        <button className="btn theme-btn" onClick={handleClick}>Change Password</button>
      </form>
    </div>
  )
}
