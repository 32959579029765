import { createSlice } from "@reduxjs/toolkit";

export const publisherSlice = createSlice({
  name: "publisher",
  initialState: {
    publishers: [],
    isFetching: false,
    error: false,
  },
  reducers: {
    //get all
    getPublisherStart: (state) => {
      state.isFetching = true;
      state.error = false;
    },
    getPublisherSuccess: (state, action) => {
      state.isFetching = false;
      state.publishers = action.payload;
    },
    getPublisherFailur: (state) => {
      state.isFetching = false;
      state.error = true;
    },

    //add
    addPublisherStart: (state) => {
      state.isFetching = true;
      state.error = false;
    },
    addPublisherSuccess: (state, action) => {
      state.isFetching = false;
      state.publishers.unshift(action.payload);
    },
    addPublisherFailur: (state) => {
      state.isFetching = false;
      state.error = true;
    },

    //UPDATE
    updatePublisherStart: (state) => {
      state.isFetching = true;
      state.error = false;
    },
    updatePublisherSuccess: (state, action) => {
      state.isFetching = false;
      state.publishers[
        state.publishers.findIndex((item) => item._id === action.payload.id)
      ] = action.payload.updatePublisher;
    },
    updatePublisherFailure: (state) => {
      state.isFetching = false;
      state.error = true;
    },

    //DELETE
    deletePublisherStart: (state) => {
      state.isFetching = true;
      state.error = false;
    },
    deletePublisherSuccess: (state, action) => {
      state.isFetching = false;
      state.publishers.splice(
        state.publishers.findIndex((item) => item._id === action.payload),
        1
      );
    },
    deletePublisherFailure: (state) => {
      state.isFetching = false;
      state.error = true;
    },
  },
});

export const { getPublisherStart, getPublisherSuccess, getPublisherFailur, addPublisherStart, addPublisherSuccess, addPublisherFailur, updatePublisherStart, updatePublisherSuccess, updatePublisherFailure,deletePublisherStart, deletePublisherSuccess, deletePublisherFailure } =
  publisherSlice.actions;

export default publisherSlice.reducer;
