import { createSlice } from "@reduxjs/toolkit";

export const blogSlice = createSlice({
  name: "blog",
  initialState: {
    blogs: [],
    isFetching: false,
    error: false,
  },
  reducers: {
    //get all
    getBlogStart: (state) => {
      state.isFetching = true;
      state.error = false;
    },
    getBlogSuccess: (state, action) => {
      state.isFetching = false;
      state.blogs = action.payload;
    },
    getBlogFailur: (state) => {
      state.isFetching = false;
      state.error = true;
    },

    //add
    addBlogStart: (state) => {
      state.isFetching = true;
      state.error = false;
    },
    addBlogSuccess: (state, action) => {
      state.isFetching = false;
      state.blogs.push(action.payload);
    },
    addBlogFailur: (state) => {
      state.isFetching = false;
      state.error = true;
    },

    //UPDATE
    updateBlogStart: (state) => {
      state.isFetching = true;
      state.error = false;
    },
    updateBlogSuccess: (state, action) => {
      state.isFetching = false;
      state.blogs[
        state.blogs.findIndex((item) => item._id === action.payload.id)
      ] = action.payload.updateBlog;
    },
    updateBlogFailure: (state) => {
      state.isFetching = false;
      state.error = true;
    },

    //DELETE
    deleteBlogStart: (state) => {
      state.isFetching = true;
      state.error = false;
    },
    deleteBlogSuccess: (state, action) => {
      state.isFetching = false;
      state.blogs.splice(
        state.blogs.findIndex((item) => item._id === action.payload),
        1
      );
    },
    deleteBlogFailure: (state) => {
      state.isFetching = false;
      state.error = true;
    },
  },
});

export const { getBlogStart, getBlogSuccess, getBlogFailur, addBlogStart, addBlogSuccess, addBlogFailur, updateBlogStart, updateBlogSuccess, updateBlogFailure,deleteBlogStart, deleteBlogSuccess, deleteBlogFailure } =
  blogSlice.actions;

export default blogSlice.reducer;
