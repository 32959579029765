import { createSlice } from "@reduxjs/toolkit";


const bookSlice = createSlice({
  name: "book",
  initialState: {
    books: [],
    isFetching: false,
    error: false,
  },
  reducers: {
    //get all
    getBookStart: (state) => {
      state.isFetching = true;
      state.error = false;
    },
    getBookSuccess: (state, action) => {
      state.isFetching = false;
      state.books = action.payload;
    },
    getBookFailur: (state) => {
      state.isFetching = false;
      state.error = true;
    },

    //add
    addBookStart: (state) => {
      state.isFetching = true;
      state.error = false;
    },
    addBookSuccess: (state, action) => {
      state.isFetching = false;
      state.books.unshift(action.payload);
    },
    addBookFailur: (state) => {
      state.isFetching = false;
      state.error = true;
    },

    //UPDATE
    updateBookStart: (state) => {
      state.isFetching = true;
      state.error = false;
    },
    updateBookSuccess: (state, action) => {
      state.isFetching = false;
      state.books[
        state.books.findIndex((item) => item._id === action.payload.id)
      ] = action.payload.updateBook;
    },
    updateBookFailure: (state) => {
      state.isFetching = false;
      state.error = true;
    },

    //DELETE
    deleteBookStart: (state) => {
      state.isFetching = true;
      state.error = false;
    },
    deleteBookSuccess: (state, action) => {
      state.isFetching = false;
      state.books.splice(
        state.books.findIndex((item) => item._id === action.payload),
        1
      );
    },
    deleteBookFailure: (state) => {
      state.isFetching = false;
      state.error = true;
    },
  },
});

export const {
  getBookStart,
  getBookSuccess,
  getBookFailur,
  addBookStart,
  addBookSuccess,
  addBookFailur,
  updateBookStart,
  updateBookSuccess,
  updateBookFailure,
  deleteBookStart,
  deleteBookSuccess,
  deleteBookFailure,
} = bookSlice.actions;

export default bookSlice.reducer;
