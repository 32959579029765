import { publicRequest, userRequest } from "./requestMethod";
import {loginStart, loginSuccess, loginFailure, logout} from "../redux/authRedux"
import { addBookFailur, addBookStart, addBookSuccess, deleteBookFailure, deleteBookStart, deleteBookSuccess, getBookFailur, getBookStart, getBookSuccess, updateBookFailure, updateBookStart, updateBookSuccess } from "../redux/bookRedux"
import { getPublisherStart, getPublisherSuccess, getPublisherFailur} from "../redux/publisherRedux"
import { getAuthorStart, getAuthorSuccess, getAuthorFailur, } from "../redux/authorRedux";
import { getCategoryStart, getCategorySuccess, getCategoryFailur} from "../redux/categoryRedux";
import { getMediaFailur, getMediaStart, getMediaSuccess } from "../redux/mediaRedux";
import { addtagFailur, addtagStart, addtagSuccess, gettagFailur, gettagStart, gettagSuccess } from "../redux/tagRedux";
import { changeFailur, changeStart, changeSuccess } from "../redux/passwordRedux";


// login
export const login = async (dispatch, user) => {
    dispatch(loginStart())
    try {
      const res = await publicRequest.post("/auth/publisher/login", user);
      dispatch(loginSuccess(res.data))
      return res.data;
    } catch (err) {
      dispatch(loginFailure());
    }
};

//change password
export const changePassword = async (dispatch, passwordDetails) => {
  dispatch(changeStart());
  try {
    const res = await publicRequest.post("/auth/publisher/changepassword", passwordDetails);
    dispatch(changeSuccess(res.data));
  } catch (err) {
    dispatch(changeFailur());
  }
};

// Get books
export const getBook = async (dispatch, publisherid) => {
  dispatch(getBookStart());
  try {
    const res = await publicRequest.get(`/products?publisherId=${publisherid}`);
    dispatch(getBookSuccess(res.data));
  } catch (err) {
    dispatch(getBookFailur());
  }
};

// Add book
export const addBook = async (book, dispatch, id) => {
  dispatch(addBookStart());
  try {
    const res = await userRequest.post(`/products/publisher/addbook/${id}`, book);
    dispatch(addBookSuccess(res.data));
  } catch (err) {
    dispatch(addBookFailur());
  }
};

export const updateBook = async (id, book, dispatch) => {
  dispatch(updateBookStart());
  try {
    const res = await userRequest.put(`/products/${id}`, book);
    const updateBook = res.data;
    dispatch(updateBookSuccess({ id, updateBook }));
  } catch (err) {
    dispatch(updateBookFailure());
  }
};

// Delete book
export const deleteBook = async (id, dispatch) => {
  dispatch(deleteBookStart());
  try {
    await userRequest.delete(`/products/${id}`);
    dispatch(deleteBookSuccess(id));
  } catch (err) {
    dispatch(deleteBookFailure());
  }
};

// Create album for publishers
export const createAlbum = async (id) => {
  try {
    const res = await userRequest.post("/publisher/createalbum", id);
  } catch (err) {
    console.log(err);
  }
};

// Get publishers
export const getPublishers = async (dispatch) => {
  dispatch(getPublisherStart());
  try {
    const res = await publicRequest.get("/publisher");
    dispatch(getPublisherSuccess(res.data));
  } catch (err) {
    dispatch(getPublisherFailur());
  }
};

// Get authors
export const getAuthor = async (dispatch) => {
  dispatch(getAuthorStart());
  try {
    const res = await publicRequest.get("/author");
    dispatch(getAuthorSuccess(res.data));
  } catch (err) {
    dispatch(getAuthorFailur());
  }
};

// Get categories
export const getCategories = async (dispatch) => {
  dispatch(getCategoryStart());
  try {
    const res = await publicRequest.get("/category");
    dispatch(getCategorySuccess(res.data));
  } catch (err) {
    dispatch(getCategoryFailur());
  }
};

// Load media
export const loadMedia = async (dispatch) => {
  dispatch(getMediaStart());
  try {
    const res = await publicRequest.get("/media");
    dispatch(getMediaSuccess(res.data));
  } catch (err) {
    dispatch(getMediaFailur());
  }
};

// Get Tags
export const getTags = async (dispatch) => {
  dispatch(gettagStart());
  try {
    const res = await publicRequest.get("/tag");
    dispatch(gettagSuccess(res.data));
  } catch (err) {
    dispatch(gettagFailur());
  }
};

// Add Tag
export const addTag = async (tags, dispatch) => {
  dispatch(addtagStart());
  try {
    const res = await userRequest.post(`/tag/add`, tags);
    dispatch(addtagSuccess(res.data));
  } catch (err) {
    dispatch(addtagFailur());
  }
};

// Update Tags
export const updateTags = async (updateTags, dispatch) => {
  
  try {
    const res = await userRequest.post(`/tag/edit-tag`, updateTags);
    getTags(dispatch);
  } catch (err) {
    console.log(err);
  }
};