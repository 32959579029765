import { createSlice } from "@reduxjs/toolkit";

export const mediaSlice = createSlice({
  name: "media",
  initialState: {
    allmedia: [],
    isFetching: false,
    error: false,
  },
  reducers: {
    //get all
    getMediaStart: (state) => {
      state.isFetching = true;
      state.error = false;
    },
    getMediaSuccess: (state, action) => {
      state.isFetching = false;
      state.allmedia = action.payload;
    },
    getMediaFailur: (state) => {
      state.isFetching = false;
      state.error = true;
    },

    deleteMediaStart: (state) => {
      state.isFetching = true;
      state.error = false;
    },
    deleteMediaSuccess: (state, action) => {
      state.isFetching = false;
      state.allmedia.splice(
        state.allmedia.findIndex((item) => item === action.payload),
        1
      );
    },
    deleteMediaFailure: (state) => {
      state.isFetching = false;
      state.error = true;
    },
  }
})

export const { getMediaStart, getMediaSuccess, getMediaFailur, deleteMediaStart, deleteMediaSuccess, deleteMediaFailure } =
  mediaSlice.actions;

export default mediaSlice.reducer;
