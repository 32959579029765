import React, {useState} from 'react'
import { useSelector,useDispatch } from 'react-redux'
import {Link} from 'react-router-dom'
import profileThumb from "../img/user.png"
import menues from "../util/menu.json"
import { logout } from '../redux/authRedux';

export default function Navbar() {
  let user = useSelector((state)=>state.userInfo.currentUser);
  const dispatch = useDispatch()
  return (
    <div className="navbar">
        <div className="searchbar">
            <div className="form-group">
            <input type="text" placeholder="Search anything..."/>
            <button type="submit" className="btn">
                <svg width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                <circle cx="6" cy="6" r="5" stroke="#758197"/>
                <path d="M9.66675 9.66669L11.6667 11.6667" stroke="#758197" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
            </button>
            </div>
        </div>
        <div className="navbar-profile-info">
            <Link to="/messages">
                <svg width="20" height="16" viewBox="0 0 20 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M19 3C19 1.89543 18.1046 1 17 1H3C1.89543 1 1 1.89543 1 3V13C1 14.1046 1.89543 15 3 15H6H10H14H17C18.1046 15 19 14.1046 19 13V3Z" stroke="#0D99FF" stroke-width="1.5" stroke-linejoin="round"/>
                    <path d="M10 8.5L9.63577 9.15562L10 9.35797L10.3642 9.15562L10 8.5ZM19.3642 4.15562C19.7263 3.95446 19.8568 3.49786 19.6556 3.13577C19.4545 2.77368 18.9979 2.64322 18.6358 2.84438L19.3642 4.15562ZM0.635768 4.15562L9.63577 9.15562L10.3642 7.84438L1.36423 2.84438L0.635768 4.15562ZM10.3642 9.15562L19.3642 4.15562L18.6358 2.84438L9.63577 7.84438L10.3642 9.15562Z" fill="#0D99FF"/>
                    <path d="M6.5 7L1 12" stroke="#0D99FF" stroke-width="1.5"/>
                    <path d="M13 7L19 12" stroke="#0D99FF" stroke-width="1.5"/>
                </svg>
            </Link>
            <Link to="/notifications">
                <svg width="18" height="20" viewBox="0 0 18 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M2.41688 15H15.5831C16.2741 15 16.7568 14.316 16.5253 13.665L15.6658 11.2475C15.3722 10.4219 15.2222 9.55219 15.2222 8.67598V7.22222C15.2222 3.78578 12.4364 1 9 1C5.56356 1 2.77778 3.78578 2.77778 7.22222V8.67598C2.77778 9.55219 2.62776 10.4219 2.33422 11.2475L1.47467 13.665C1.2432 14.316 1.72595 15 2.41688 15Z" stroke="#0D99FF" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M13 15C13 17.2091 11.2091 19 9 19C6.79086 19 5 17.2091 5 15" stroke="#0D99FF" stroke-width="1.5"/>
                </svg>
            </Link>
            <Link to="profile" className="profileThumb">
                <div className="profilethumbimg">
                    <img src={user.photo} alt="admin"/>
                </div>
                <div className="profilethumbinfo">
                    <h1>{user.bn_publication}</h1>
                    <p>{user.publisher}</p>
                </div>
                <div className="dropdown-profile">
                    <Link to="/">Profile</Link>
                    <Link to="/">Admin Roles</Link>
                    <Link to="/sitesetting">Site Settings</Link>
                    <a onClick={()=> {dispatch(logout())}}>Logout</a>
                </div>
            </Link>
        </div>
    </div>
  )
}
