import { createSlice } from "@reduxjs/toolkit";

export const tagSlice = createSlice({
  name: "tag",
  initialState: {
    tags: [],
    isFetching: false,
    error: false,
  },
  reducers: {
    //get all
    gettagStart: (state) => {
      state.isFetching = true;
      state.error = false;
    },
    gettagSuccess: (state, action) => {
      state.isFetching = false;
      state.tags = action.payload;
    },
    gettagFailur: (state) => {
      state.isFetching = false;
      state.error = true;
    },

    //add
    addtagStart: (state) => {
      state.isFetching = true;
      state.error = false;
    },
    addtagSuccess: (state, action) => {
      state.isFetching = false;
      state.tags.push(action.payload);
    },
    addtagFailur: (state) => {
      state.isFetching = false;
      state.error = true;
    },

    // //UPDATE
    // updateBlogStart: (state) => {
    //   state.isFetching = true;
    //   state.error = false;
    // },
    // updateBlogSuccess: (state, action) => {
    //   state.isFetching = false;
    //   state.blogs[
    //     state.blogs.findIndex((item) => item._id === action.payload.id)
    //   ] = action.payload.updateBlog;
    // },
    // updateBlogFailure: (state) => {
    //   state.isFetching = false;
    //   state.error = true;
    // },

    // //DELETE
    // deleteBlogStart: (state) => {
    //   state.isFetching = true;
    //   state.error = false;
    // },
    // deleteBlogSuccess: (state, action) => {
    //   state.isFetching = false;
    //   state.blogs.splice(
    //     state.blogs.findIndex((item) => item._id === action.payload),
    //     1
    //   );
    // },
    // deleteBlogFailure: (state) => {
    //   state.isFetching = false;
    //   state.error = true;
    // },
  },
});

export const { gettagStart, gettagSuccess, gettagFailur, addtagStart, addtagSuccess, addtagFailur, updateBlogStart, updateBlogSuccess, updateBlogFailure,deleteBlogStart, deleteBlogSuccess, deleteBlogFailure } =
  tagSlice.actions;

export default tagSlice.reducer;
